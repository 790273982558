.auto .MuiAutocomplete-root {
   background-color: greenyellow;
   color:aliceblue;
}

.auto .MuiInputBase-input {
    height: 7px;
}

.auto .MuiOutlinedInput {
    border-color: "purple"
  }