.orangeSlider .MuiSlider-root {
    color: #EF9654;
}
.orangeSlider .MuiSlider-thumb {
    color: #EF9654;
}
.orangeSlider .MuiSlider-thumb:focus {
    box-shadow: 0 0 0 8px rgba(239, 150, 84, .2) !important;
}
.orangeSlider .MuiSlider-thumb:hover {
    box-shadow: 0 0 0 8px rgba(239, 150, 84, .3) !important;
}
.orangeSlider .MuiSlider-thumb:active {
    box-shadow: 0 0 0 8px rgba(239, 150, 84, .3) !important;
}
.orangeSlider .MuiSlider-thumb.Mui-focusVisible{
    box-shadow: 0 0 0 10px rgba(239, 150, 84, .3) !important;
}
.orangeSlider .MuiSlider-markLabel{
    font-size: 18px;
    font-weight: bold;
    color: black;
}


.greenSlider .MuiSlider-root {
    color: #5BC73A;
}
.greenSlider .MuiSlider-thumb {
    color: #5BC73A;
}
.greenSlider .MuiSlider-thumb:focus {
    box-shadow: 0 0 0 8px rgba(91, 199, 58, .3) !important;
}
.greenSlider .MuiSlider-thumb:hover {
    box-shadow: 0 0 0 8px rgba(91, 199, 58, .3) !important;
}
.greenSlider .MuiSlider-thumb:active {
    box-shadow: 0 0 0 8px rgba(91, 199, 58, .3) !important;
}
.greenSlider .MuiSlider-thumb.Mui-focusVisible{
    box-shadow: 0 0 0 10px rgba(91, 199, 58, .3) !important;
}
.greenSlider .MuiSlider-markLabel{
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.blueSlider .MuiSlider-markLabel {
    font-size: 18px;
    font-weight: bold;
    color: black;
}